export default class Case {
  html;
  aims;
  participants;
  name;
  difficulty;
  spread_;
  duration;
  date;
  status;
  chatLink;
  answerJson;
  answerJsonAims;
  isCurrent;
  caseNumberToHide;
  caseContentAnswer;

  constructor(
    html,
    aims,
    participants,
    name,
    difficulty,
    spread_,
    duration,
    dateNow,
    status,
    chatLink,
    answerJson,
    answerJsonAims,
    isCurrent,
    caseNumberToHide,
    caseContentAnswer
  ) {
    this.html = html;
    this.aims = aims;
    this.participants = participants;
    this.name = name;
    this.difficulty = difficulty;
    this.spread_ = spread_;
    this.duration = duration;
    this.date = dateNow;
    this.status = status;
    this.chatLink = chatLink;
    this.answerJson = answerJson;
    this.answerJsonAims = answerJsonAims;
    this.isCurrent = isCurrent;
    this.caseNumberToHide = caseNumberToHide;
    this.caseContentAnswer = caseContentAnswer;
  }
}
