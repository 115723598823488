<template>
  <div>
    <el-dialog
      :title="$t('Добавить из избранного')"
      v-model="addFromCartDialog"
      width="60%"
    >
      <el-transfer
        id="cart-transfer"
        style="text-align: left; display: inline-block"
        filterable
        :format="{
          noChecked: '${total}',
          hasChecked: '${checked}/${total}'
        }"
        :filter-placeholder="$t('Поиск')"
        :titles="[$t('Избранное'), $t('Цели')]"
        v-model="pickedNodes"
        :props="{
          key: 'name',
          label: 'name'
        }"
        :data="cartTransfer"
      >
      </el-transfer>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="addFromCartDialog = false">{{ $t('Отмена') }}</el-button>
          <el-button type="primary" @click="selectCartElements"
            >{{ $t('Подтвердить') }}</el-button>
        </span>
      </template>
    </el-dialog>

    <el-dialog :title="$t('Поиск по базе')" v-model="addFromNeoDialog" width="60%">
      <NeoSearch
        ref="neoSearch"
        :selectedAims="form.aims"
        :selectionOn="true"
      ></NeoSearch>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="addFromNeoDialog = false">{{ $t('Отмена') }}</el-button>
          <el-button type="primary" @click="selectCartElementsSearch"
            >{{ $t('Добавить') }}</el-button
          >
        </span>
      </template>
    </el-dialog>

    <div v-loading.fullscreen.lock="loading">
      <el-row :style="{ display: 'flex', justifyContent: 'center' }">
        <el-card
          :style="{
            position: 'fixed',
            top: '100px',
            left: '20px',
            textAlign: 'start'
          }"
        >
          <el-row>
            <el-button @click="backToCases" style="width: 100%">
              <i class="fas fa-chevron-left" style="margin-right: 2px"> </i>
              {{ $t('Назад к кейсам') }}
            </el-button>
          </el-row>
          <el-row style="margin-top: 8px">
            <el-button type="success" style="width: 100%" @click="saveCase">
              {{ $t('Сохранить') }}
              <i class="fas fa-check" style="margin-left: 4px"> </i>
            </el-button>
          </el-row>
          <el-row
            :style="{ marginTop: '30px', flexDirection: 'column', gap: '10px' }"
          >
            <el-link @click.prevent="scrollTo('name')" :underline="false">
              {{ $t('Название') }}
            </el-link>
            <el-link @click.prevent="scrollTo('text')" :underline="false">
              {{ $t('Текст') }}
            </el-link>
            <el-link @click.prevent="scrollTo('targets')" :underline="false">
              {{ $t('Цели') }}
            </el-link>
            <el-link
              @click.prevent="scrollTo('participants')"
              :underline="false"
            >
              {{ $t('Участники') }}
            </el-link>
            <el-link @click.prevent="scrollTo('parameters')" :underline="false">
              {{ $t('Параметры') }}
            </el-link>
          </el-row>
        </el-card>

        <el-col :span="14">
          <el-card style="width:100%">
            <div class="section-label">
              <span ref="name">{{ $t('Название') }}</span>
            </div>
            <el-input
              size="medium"
              style="font-size: 18px"
              :placeholder="$t('Кейс')"
              v-model="form.name"
            ></el-input>
            <hr />
            <div class="section-label">
              <span ref="text">{{ $t('Текст') }}</span>
            </div>
            <div>
              <vue-editor
                :style="{ width: '100%', height: '100%', padding: '0px' }"
                v-model="caseContent"
                spellcheck="true"
              />
            </div>
            <hr />
            <div class="section-label">
              <span ref="text">{{ $t('форма запроса') }}</span>
            </div>
            <div>
              <vue-editor
                :style="{ width: '100%', height: '100%', padding: '0px' }"
                v-model="caseContentAnswer"
                :editorToolbar="customToolbar"
              />
              <!--              <el-button @click="createAnswerDynamycRender()">-->
              <!--              </el-button>-->
              <!--               <DynamicRenderer-->
              <!--                v-bind:typeSchema="typeSchema"-->
              <!--                :data="data"-->
              <!--                @input="updateData($event)"-->
              <!--              />-->
            </div>
            <hr />
            <div class="section-label">
              <span ref="targets" :style="{ fontFamily: 'inherit' }">
                {{ $t('Цели') }}
              </span>
            </div>
            <div>
              <el-row :style="{ justifyContent: 'center' }">
                <el-col :span="16" :style="{ width: '100%' }">
                  <el-card>
                    <div :style="{ width: '100%', height: height - 48 + 'px' }">
                      <el-row>
                        <el-button @click="addFromCartDialog = true">
                          {{ $t('Из избранного') }}
                        </el-button>
                        <el-button
                          @click="addFromNeoDialog = true"
                          style="margin-left: 8px"
                        >
                          {{ $t('Поиск') }}
                        </el-button>
                      </el-row>
                      <el-row style="margin-top: 8px">
                        <el-table :data="form.aims" style="width: 100%">
                          <el-table-column prop="id" label="ID">
                          </el-table-column>
                          <el-table-column prop="name" :label="$t('Описание')">
                          </el-table-column>
                          <el-table-column fixed="right" label="" width="80">
                            <template #default="props">
                              <el-button
                                type="danger"
                                @click="deleteAim(props.row)"
                                size="small"
                              >
                                <i class="fas fa-trash fa-lg"></i>
                              </el-button>
                            </template>
                          </el-table-column>
                        </el-table>
                      </el-row>
                    </div>
                  </el-card>
                </el-col>
              </el-row>
            </div>
            <hr />
            <div class="section-label">
              <span ref="participants">{{ $t('Участники') }}</span>
            </div>
            <div>
              <el-transfer
                style="text-align: left; display: inline-block"
                filterable
                :format="{
                  noChecked: '${total}',
                  hasChecked: '${checked}/${total}'
                }"
                :filter-placeholder="$t('Поиск')"
                :titles="[$t('Пользователи'), $t('Участники')]"
                v-model="pickedParticipants"
                :props="{
                  key: 'id',
                  label: 'name'
                }"
                :data="userTransfer"
                @change="handleChange"
              >
              </el-transfer>
            </div>
            <hr />
            <div class="section-label">
              <span ref="parameters">{{ $t('Параметры') }}</span>
            </div>
            <div>
              <!-- <hr/> -->
              <div style="text-align: start">
                <el-form ref="form" :model="form" label-width="210px">
                  <el-form-item :label="$t('Задайте уровень сложности')">
                    <el-radio-group v-model="form.difficulty">
                      <el-radio-button label="1"></el-radio-button>
                      <el-radio-button label="2"></el-radio-button>
                      <el-radio-button label="3"></el-radio-button>
                      <el-radio-button label="4"></el-radio-button>
                      <el-radio-button label="5"></el-radio-button>
                    </el-radio-group>
                  </el-form-item>
                  <el-form-item :label="$t('Задайте уровень распространенности')">
                    <el-radio-group v-model="form.spread_">
                      <el-radio-button label="1"></el-radio-button>
                      <el-radio-button label="2"></el-radio-button>
                      <el-radio-button label="3"></el-radio-button>
                      <el-radio-button label="4"></el-radio-button>
                      <el-radio-button label="5"></el-radio-button>
                    </el-radio-group>
                  </el-form-item>
                  <el-form-item :label="$t('Продолжительность (мин)')">
                    <el-input-number
                      v-model="form.duration"
                      :min="1"
                    ></el-input-number>
                  </el-form-item>
                  <el-form-item
                    :label="$t('Ссылка на чат (Telegram или другой мессенджер на усмотрение)')"
                  >
                    <el-input type="text" v-model="form.chatLink"></el-input>
                  </el-form-item>
                  <el-form-item label="caseNumberToHide">
                    <el-input type="text" v-model="form.caseNumberToHide"></el-input>
                  </el-form-item>
                </el-form>
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import html2pdf from "html2pdf.js";
import CaseController from "../../controllers/case.controller";
import { store } from "../../store";
import NeoSearch from "../NeoSearch/NeoSearch";
import VisController from "../../controllers/vis.controller";
import Case from "../../models/case.model";

export default {
  name: "NewCase",
  components: { NeoSearch },
  data() {
    return {
      // height: Math.max(
      //     document.documentElement.clientHeight,
      //     window.innerHeight || 0
      // ) - 150,
      // step: 0,
      customToolbar: [[], [], []],
      typeSchema: [],
      pathName: null,
      isCurrent: true,
      pickedNodes: [],
      pickedParticipants: [],
      caseContent: "<h1>" + this.$t('Введите текст кейса') + "</h1>",
      caseContentAnswer: "[[]]",
      userTransfer: [],
      cartTransfer: [],
      form: {
        aims: [],
        participants: [],
        name: "",
        difficulty: "",
        spread_: "",
        duration: "",
        chatLink: "",
        caseNumberToHide: "1"
      },
      answerJson: "", // Нужно при дублировании и редактировании кейса
      answerJsonAims: {}, // Нужно при дублировании и редактировании кейса
      neoSearch: {
        label: "",
        type: "noID",
        query: "",
        data: []
      },
      ontologyId: null,
      addFromCartDialog: false,
      addFromNeoDialog: false,
      caseController: null,
      visController: null,
      loading: true,
      waitForSearch: false
    };
  },
  computed: {},
  mounted() {
    const vm = this;

    vm.caseController = new CaseController();
    vm.pathName = vm.$route.name;
    vm.userTransfer = [];
    vm.cartTransfer = vm.caseController.getCart();

    if (vm.pathName === "editCase" || vm.pathName === "duplicateCase") {
      let caseParticipantsIDs = [];

      vm.visController = new VisController();

      vm.caseController
        .caseById(vm.$route.params.caseId)
        .then(response => {
          let casebyId = new Case(
            response.data.html,
            response.data.aims,
            response.data.participants,
            response.data.name,
            response.data.difficulty,
            response.data.spread_,
            response.data.duration,
            response.data.date,
            response.data.status,
            response.data.chatLink,
            response.data.answerJson,
            response.data.answerJsonAims,
            response.data.isCurrent,
            response.data.caseNumberToHide,
            response.data.caseContentAnswer
          );

          const neoSearch = {
            idList: casebyId.aims
          };

          //Заполнение таблицы целей
          vm.visController.searchNodeByIdList(neoSearch).then(response => {
            for (let element of response.data.result) {
              let resp = [];
              for (let attr of element[2]) {
                resp.push(attr[0] + ": " + attr[1]);
              }
              const aimNode = {
                query: response.data.query,
                id: element[0],
                type: element[1][0],
                response: resp.join(", ")
              };

              vm.form.aims.push({
                id: aimNode.id.toString(),
                name: aimNode.response
              });
            }
          });

          if (vm.pathName === "duplicateCase") {
            vm.form.name = casebyId.name + `_${Math.floor(Math.random() * 50)}`;
          } else {
            vm.form.name = casebyId.name;
          }

          vm.form.answerJson = casebyId.answerJson;
          vm.caseContentAnswer = casebyId.answerJson;
          vm.answerJsonAims = casebyId.answerJsonAims;
          vm.form.difficulty = casebyId.difficulty;
          vm.form.spread_ = casebyId.spread_;
          vm.form.duration = casebyId.duration;
          vm.form.chatLink = casebyId.chatLink;
          vm.form.chatLink = casebyId.chatLink;
          vm.form.caseNumberToHide = casebyId.caseNumberToHide;
          // TODO проверить чтобы caseContent был верный!
          vm.caseContent = casebyId.html;
          // vm.caseContent += '\n-----\n' + answerJson.toString()
          vm.ontologyId = casebyId._id;
          vm.isCurrent = casebyId.isCurrent;

          // Заполнение трансвера целей
          vm.cartTransfer.forEach(aim => {
            const id = aim.name.split(" : ")[1];

            if (casebyId.aims.indexOf(id) >= 0) {
              const pickedNodeName = aim.name;

              vm.pickedNodes.push(pickedNodeName);
            }
          });

          caseParticipantsIDs = casebyId.participants;

          // Заполнение трансвера участников
          vm.caseController.getAllUsers().then(response => {
            const allUsers = response.data;

            caseParticipantsIDs.forEach(id => {
              const pickedUser = allUsers.find(user => user._id === id);
              if (pickedUser) {
                vm.pickedParticipants.push(pickedUser._id);
              }
            });

            vm.userTransfer = Array.from(allUsers, user => ({
              id: user._id,
              name: user.username
            }));
          });
        })
        .finally(() => {
          vm.loading = false;
        });
    } else {
      vm.ontologyId = store.getters["ontologyStore/getOntologyStore"].id;

      vm.caseController
        .getAllUsers()
        .then(response => {
          for (let user of response.data) {
            vm.userTransfer.push({
              id: user._id,
              name: user.username
            });
          }
        })
        .finally(() => {
          vm.loading = false;
        });
    }
  },
  methods: {
    createAnswerDynamycRender() {
      this.typeSchema = JSON.parse(
        this.caseContentAnswer
          .replace(/&nbsp;/g, " ")
          .replace(/<[^>]*>/g, "")
          .replace(/<p>/g, "")
          .replace(/<\/p>/g, "")
          .replace(/\\/g, "")
      );
    },

    handleChange() {
      console.log("change, pickedParticipants", this.pickedParticipants);
      console.log("change, userTransfer", this.userTransfer);
    },
    exportToPDF() {
      console.log(this.caseContent);
      const doc = document.getElementsByClassName("ql-editor")[0];
      doc.setAttribute("id", "canvas");
      doc.setAttribute(
        "style",
        "position: absolute; top:0px; left: 5000px; width: 670px; height: auto"
      );
      document.body.appendChild(doc);

      console.log(doc.innerHTML);

      const options = {
        margin: 15,
        filename: "document.pdf",
        pagebreak: { mode: ["css", "legacy"] },
        image: { type: "jpeg", quality: 1 },
        html2canvas: { scrollY: -window.scrollY },
        jsPDF: { unit: "mm", format: "a4" }
      };
      html2pdf()
        .set(options)
        .from(doc)
        .save()
        .then(function() {
          //doc.remove()
        });
    },
    deleteAim(row) {
      const vm = this;

      for (const aim of vm.form.aims) {
        if (aim.id === row.id) {
          vm.form.aims.splice(vm.form.aims.indexOf(aim), 1);

          for (const pickedNode of vm.pickedNodes) {
            const id = pickedNode.split(" : ")[1];

            if (id === row.id) {
              vm.pickedNodes.splice(vm.pickedNodes.indexOf(pickedNode), 1);
              break;
            }
          }
          break;
        }
      }
    },
    prevStep() {
      this.step--;
    },
    selectCartElements() {
      this.addFromCartDialog = false;
      for (let node of this.cartTransfer) {
        if (this.pickedNodes.includes(node.name)) {
          const aim = {
            id: node.name.split(" : ")[1],
            name: node.text
          };

          if (!this._isAimInAims(aim.id, this.form.aims)) {
            this.form.aims.push(aim);
          }
        }
      }
    },
    selectCartElementsSearch() {
      this.addFromNeoDialog = false;
      for (let node of this.$refs.neoSearch.multipleSelection) {
        const aim = {
          id: node.id.toString(),
          name: node.response
        };

        if (!this._isAimInAims(aim.id, this.form.aims)) {
          this.form.aims.push(aim);
        }
      }
    },
    _isAimInAims(aimId, aims) {
      for (const aim of aims) {
        if (aim.id === aimId) return true;
      }
      return false;
    },
    saveCase() {
      const vm = this;

      let idAims = [];
      for (let aim of this.form.aims) {
        idAims.push(aim.id);
      }
      let dateEditing = new Date().getTime();
      let statusNow = false;
      let newCase = new Case(
        this.caseContent,
        idAims,
        this.pickedParticipants,
        this.form.name,
        this.form.difficulty,
        this.form.spread_,
        this.form.duration || "",
        dateEditing,
        statusNow,
        this.form.chatLink,
        this.answerJson,
        this.answerJsonAims,
        this.isCurrent,
        this.form.caseNumberToHide,
        this.caseContentAnswer
      );

      switch (this.pathName) {
        case "editCase":
          this.caseController
            .editCase(newCase, vm.$route.params.caseId)
            .then(() => {
              vm.$router.push({
                name: "Cases",
                params: { component: "cases" }
              });
            });
          break;
        case "newCase":
          console.log("посылаем запрос на создание кейса", newCase);
          this.caseController.saveCase(newCase).then(() => {
            vm.$router.push({ name: "Cases", params: { component: "cases" } });
          });
          break;
        case "duplicateCase":
          console.log("посылаем запрос на дублирование кейса", newCase);
          this.caseController.duplicateCase(newCase).then(() => {
            vm.$router.push({ name: "Cases", params: { component: "cases" } });
          });
          break;
        default:
          console.log("Неизвестный путь: ", this.pathName);
      }
    },
    backToCases() {
      const vm = this;
      this.$confirm(
        this.$t("Данное действие удалит весь прогресс конструирования кейса. Продолжить?"),
          this.$t("Предупреждение"),
        {
          confirmButtonText: this.$t("Да"),
          cancelButtonText: this.$t("Нет"),
          type: "warning"
        }
      )
        .then(() => {
          vm.$router.push({ name: "Cases", params: { component: "cases" } });
        })
        .catch(() => {});
    },
    scrollTo(sectionName) {
      const vm = this;
      const topOffset = document.querySelector(".el-menu").clientHeight + 10;
      let scrollTarget = null;

      switch (sectionName) {
        case "name":
          scrollTarget = vm.$refs.name;
          break;
        case "text":
          scrollTarget = vm.$refs.text;
          break;
        case "targets":
          scrollTarget = vm.$refs.targets;
          break;
        case "participants":
          scrollTarget = vm.$refs.participants;
          break;
        case "parameters":
          scrollTarget = vm.$refs.parameters;
          break;
      }

      const elementPosition = scrollTarget.getBoundingClientRect().top;
      const offsetPosition = elementPosition - topOffset;

      window.scrollBy({
        top: offsetPosition,
        behavior: "smooth"
      });
    }
  }
};
</script>

<style scoped>
.el-transfer-panel {
  width: 300px;
}

.section-label {
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  font-size: 24px;
  color: #606266;
}
</style>
